import React from 'react';
import logo from '../../images/logo9.png'
import logooutline from '../../images/logo10.png'
import read from '../../images/readmore.png'
import avatar from '../../images/avatar.png'
import c4me from '../../images/c4me.png'
import wireframer from '../../images/wireframer.png'
import todolists from '../../images/todolists.png'
import voxelcars from '../../images/voxelcars.png'
import linkedin from '../../images/linkedin-3-128.png'
import github from '../../images/github-128.png'
import email from '../../images/email-5-128.png'
import twitter from '../../images/twitter-128.png'

class HomeScreen extends React.Component {
  constructor(props){
    super(props);
    this.right1 = "100%";
  }

  componentDidMount(){
    let self = this;
    let skillsLocked = false;
    let projectsLocked = false;
    let contactLocked = false;
    let clientSize = window.innerWidth;
    window.addEventListener('resize',function (e){
      if (clientSize >= 1025){
        if (window.innerWidth < 1025){
          clientSize = 1024;
          let bg = document.querySelector('.contact-banner');
          bg.style.transform = "scaleX(1)";
          bg = document.querySelector('.contact-text-container');
          bg.style.transform = "translateX(0)";
          bg = document.querySelector('.contact-banner-text');
          bg.style.lineHeight= "200px";
          bg.style.fontSize= "32pt";
        }
        else{
          clientSize = 1025;
          let bg = document.querySelector('.contact-banner');
          bg.style.transform = "scaleX(.3)";
          bg = document.querySelector('.contact-text-container');
          bg.style.transform = "translateX(35%)";
          bg = document.querySelector('.contact-banner-text');
          bg.style.lineHeight= "800px";
          bg.style.fontSize= "32pt";
        }
      }
      else if (clientSize >= 642 && clientSize < 1025){
        if (window.innerWidth < 642){
          clientSize = 641;
          let bg = document.querySelector('.skills-banner');
          bg.style.paddingTop = "0px";
          bg.style.transform = "scaleX(1)";
          bg = document.querySelector('.skills-bubble-text-container');
          bg.style.animationPlayState = "paused";
          bg.style.marginTop = "50px";
          bg.style.transform = "scaleX(1)";
          bg = document.querySelector('.skills-bubble-text');
          bg.style.lineHeight = "100px";
          bg.style.opacity = 1;
          bg.style.fontSize = "24pt";
          bg.style.animationPlayState = "paused";
          bg = document.querySelector('.projects-banner');
          bg.style.transform = "scaleY(1)";
          bg = document.querySelector('.projects-banner-text');
          bg.style.lineHeight = "200px";
          bg.style.fontSize = "24pt";
          bg = document.querySelector('.projects-banner-text-container');
          bg.style.height = "200px";
          bg = document.querySelector('.contact-banner-text');
          bg.style.fontSize= "24pt";
        }
        if(window.innerWidth >= 1025){
          clientSize = 1025;
        }
      }
      else{
        if (window.innerWidth >= 642){
          clientSize = 642;
          let bg = document.querySelector('.skills-banner');
          bg.style.marginTop = "0%";
          bg.style.paddingTop = "150px";
          bg.style.transform = "scaleX(.4)";
          bg = document.querySelector('.skills-bubble-text-container');
          bg.style.animationPlayState = "running";
          bg.style.marginTop = "100px";
          bg.style.transform = "scaleX(2.5)";
          bg = document.querySelector('.skills-bubble-text');
          bg.style.lineHeight = "300px";
          bg.style.opacity = 1;
          bg.style.fontSize = "32pt";
          bg.style.animationPlayState = "running";
          bg = document.querySelector('.projects-banner');
          bg.style.transform = "scaleY(.2)";
          bg = document.querySelector('.projects-banner-text');
          bg.style.lineHeight = "200px";
          bg.style.fontSize = "32pt";
          bg = document.querySelector('.projects-banner-text-container');
          bg.style.height = "200px";
          bg = document.querySelector('.contact-banner-text');
          bg.style.fontSize= "32pt";
        }
      }
      skillsLocked = false;
      projectsLocked = false;
      contactLocked = false;
    });
    window.addEventListener('scroll',function (e){
      var backgrounds = document.querySelectorAll('.parallax-div');
      backgrounds.forEach(background => {
        // Parallax explanation:
        // The goal here was to make it so that divs would completely slide in once the div was 90% away from the top.
        // The solution was to take the div's top variable and the client's height and measure how far along it was.
        // Once the div's position was 10% of the way through, that meant it should be fully loaded.
        // NEW:
        // Now, we simply translate it and leave it there.  Better to just leave the divs once they load.
        // OLD:
        // To smoothly transition, we start from a right value of 100% and gradually subtract based on the current position up to 40% (the divs should reach 60% right, so 100-40 = 60).  For example, if the div is 20% through, then .2/.4 = .5, 100 - .5*.4*100 = 100 - 20 = 80%.  Therefore, since the div's y position is halfway through to 40%, its right value should be halfway through to 60% from 100%, which is 80% right.
        var positionY = (window.innerHeight-background.getBoundingClientRect().top)/window.innerHeight;
        if(positionY >= .01){
          background.style.right = "60%";
          background.style.opacity = 1;
        }
        else{
          // background.style.right = 100-(positionY/.4)*.4*100 + "%";
          // background.style.opacity = (positionY/.4)-.2;
        }
      });

      // Homescreen adjustment

      let bodybg = document.querySelector('.splash-screen-banner');
      let bodyPos = (window.innerHeight-bodybg.getBoundingClientRect().bottom)/window.innerHeight;
      bodybg.style.opacity = 1-bodyPos;

      let navbarbg = document.querySelector('.nav-row');
      navbarbg.style.backgroundColor = "rgba("+10+","+10+","+10+","+(bodyPos)+")";

      // End Homescreen adjustment


      // Optional: no homescreen

      // let navbarbg = document.querySelector('.nav-wrapper');
      // navbarbg.style.backgroundColor = "rgba("+10+","+10+","+10+","+1+")";

      // End optional

      // backgrounds = document.querySelectorAll('.parallax-div-right');
      // backgrounds.forEach(background => {
      //   var positionY = (window.innerHeight-background.getBoundingClientRect().top)/window.innerHeight;
      //   if(positionY >= .01){
      //     background.style.left = "60%";
      //     background.style.opacity = 1;
      //   }
      // });

// Skills Transitions

      backgrounds = document.querySelectorAll('.skills-banner');
      backgrounds.forEach(background => {
        var positionY = (window.innerHeight-background.getBoundingClientRect().top)/window.innerHeight;
        if(window.innerWidth > 641 && !skillsLocked && positionY >= .6){
          skillsLocked = true;
          background.style.marginTop = "0%";
          background.style.paddingTop = "150px";
          // background.style.maxWidth = "40vw";
          background.style.transform = "scaleX(.4)";
          let bg = document.querySelector('.skills-bubble-container');
          bg.style.transform = "scaleX(2.5)";
          bg = document.querySelector('.skills-bubble-container-1');
          bg.style.transform = "scaleX(2.5)";
          bg = document.querySelector('.skills-bubble-container-2');
          bg.style.transform = "scaleX(2.5)";
          bg = document.querySelector('.skills-bubble-container-3');
          bg.style.transform = "scaleX(2.5)";
          bg = document.querySelector('.skills-bubble');
          bg.style.marginTop = "0%";
          bg.style.opacity = 1;
          bg = document.querySelector('.skills-bubble-1');
          bg.style.marginTop = "0%";
          bg.style.opacity = 1;
          bg = document.querySelector('.skills-bubble-2');
          bg.style.marginTop = "0%";
          bg.style.opacity = 1;
          bg = document.querySelector('.skills-bubble-3');
          bg.style.marginTop = "0%";
          bg.style.opacity = 1;
          bg = document.querySelector('.skills-bubble-text');
          bg.style.lineHeight = "300px";
          bg.style.opacity = 1;
          bg.style.fontSize = "32pt";
          bg.style.animationPlayState = "running";
          bg = document.querySelector('.skills-bubble-text-container');
          bg.style.animationPlayState = "running";
          bg.style.marginTop = "100px";
          bg.style.transform = "scaleX(2.5)";
          let skillLevelBackgrounds = document.querySelectorAll('.skill-level-holder');
          skillLevelBackgrounds.forEach(function(slbackground, index){
            setTimeout(function(){
              slbackground.classList.remove('skill-level-holder');
            }, 100*index);

          })
        }
        if(!skillsLocked && positionY >= .6){
          let skillLevelBackgrounds = document.querySelectorAll('.skill-level-holder');
          skillLevelBackgrounds.forEach(function(slbackground, index){
            setTimeout(function(){
              slbackground.classList.remove('skill-level-holder');
            }, 100*index);

          })
        }
      });

// Projects Transitions

      backgrounds = document.querySelectorAll('.projects-banner');
      backgrounds.forEach(background => {
        var positionY = (window.innerHeight-background.getBoundingClientRect().top)/window.innerHeight;
        if(window.innerWidth > 641 && !projectsLocked && positionY >= .6){
          projectsLocked = true;
          background.style.marginTop = "0%";
          background.style.transform = "scaleY(.2)";
          let bg = document.querySelector('.projects-banner-text');
          bg.style.lineHeight = "200px";
          bg.style.fontSize = "32pt";
          bg = document.querySelector('.projects-banner-text-container');
          bg.style.height = "200px";
          let projectBackgrounds = document.querySelectorAll('.project-holder');
          projectBackgrounds.forEach(function(pbackground, index){
            setTimeout(function(){
              pbackground.classList.remove('project-holder');
            }, 300*index);

          })
        }
        if(window.innerWidth <= 641 && !projectsLocked && positionY >= .6){
          let projectBackgrounds = document.querySelectorAll('.project-holder');
          if (projectBackgrounds.length === 0){
            projectsLocked = true;
            console.log("Loaded all projects");
          }
          projectBackgrounds.forEach(function(pbackground){
            var positionY = (window.innerHeight-pbackground.getBoundingClientRect().top)/window.innerHeight;
            if(positionY >= .3){
              pbackground.classList.remove('project-holder');
            }
          })
        }
      });

// Contact Transitions
      backgrounds = document.querySelectorAll('.contact-banner');
      backgrounds.forEach(background => {
        var positionY = (window.innerHeight-background.getBoundingClientRect().top)/window.innerHeight;
        if(window.innerWidth > 1024 && !contactLocked && positionY >= .6){
          contactLocked = true;
          // background.style.maxWidth = "40vw";
          background.style.transform = "scaleX(.3)";
          let bg = document.querySelector('.contact-text-container');
          bg.style.transform = "translateX(35%)"
          bg = document.querySelector('.contact-banner-text');
          bg.style.lineHeight= "800px";
          bg.style.fontSize= "32pt";
        }
        if(window.innerWidth <= 1024 && !contactLocked && positionY >= .6){
        }
      });
    });
  }

  scrollTo(element, mobile) {
    if (mobile){
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: element.offsetTop - 52
      });
    }
    else{
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: element.offsetTop - 77
      });
    }
  }

  goTo(place,mobile = false){
    switch(place){
      case "about": this.scrollTo(document.querySelector('.about-me-banner'),mobile);
                    break;
      default: console.log("Unknown");
    }
  }

  render() {

    return (
      <div className="outlet">


{/* SPLASH SCREEN */}
        <div className="splash-screen-banner">
          <div className="logo-space">
            <img id="logo" src={logo} onClick={()=>this.goTo("about")}></img>
            <img id="logo-mobile" src={logo} onClick={()=>this.goTo("about", true)}></img>
            <div className="splash-name-display">
              <span className="splash-first-letter">S</span>EAN <span className="splash-first-letter">C</span>ARL <span className="splash-first-letter">S</span>PENCER
            </div>
          </div>
          <div className="scroll-indicator" onClick={()=>this.goTo("about")}>
            <img id="read-more" src={read}></img>
            <div className="scroll-text">
              Scroll down to continue.
            </div>
          </div>
        </div>



{/* ABOUT ME */}
        <div className="about-me-transition">
        </div>
        <div className="about-me-banner">
          <div className="row about-row">
            <div className="about-col-left">
              <img src={avatar} className="avatar"></img>
            </div>
            <div className="about-col-right">
              <div className="about-info-container">
                <h4 className="about-me-title">About Me</h4>
                <hr style={{width:"100%"}}></hr>
                <p className="about-me-info">My name is Sean Spencer, and I am a graduate of <a href="https://www.cs.stonybrook.edu" className="about-me-highlight">Stony Brook University</a>, where I earned a Bachelor's Degree in Computer Science.  Aspiring to become a Web Developer or Software Engineer, I hope to one day share my knowledge and creativity with the rest of the world through any and all forms of media.</p>
                <p className="about-me-info">I have always been passionate about creating media for people to use and enjoy.  My hobbies reflect that passion; I enjoy writing, art, singing, as well as creating video games and websites.</p>
                <p className="about-me-info">I am constantly searching for ways to improve the things I create. My goal in life is to be able to create something that people all around the world can use, and at the end of the day, I can say with a big, proud smile on my face, "I made that."</p>
              </div>
            </div>
          </div>
        </div>




{/* SKILLS */}
        <div className="skills-banner-container">
          <div className="skills-banner">
            <div className="skills-bubble-container">
              <div className="skills-bubble">
              </div>
            </div>
            <div className="skills-bubble-text-container">
              <h3 className="skills-bubble-text">
                <span className="skills-first-letter">S</span>KILLS
              </h3>
            </div>
            <div className="skills-bubble-spacer">
              
            </div>
            <div className="skills-bubble-container-1">
              <div className="skills-bubble-1">

              </div>
            </div>
            <div className="skills-bubble-container-2">
              <div className="skills-bubble-2">

              </div>
            </div>
            <div className="skills-bubble-container-3">
              <div className="skills-bubble-3">

              </div>
            </div>
          </div>

          <div className="skills-outlet">
            <h5 className="skills-level-header">Web Development</h5>
            <dl>
              <dd className="skill-level skill-level-holder percentage-70">
                <span className="skill-level-text">
                  JavaScript
                </span>
              </dd>
              <dd className="skill-level skill-level-holder percentage-60">
                <span className="skill-level-text">
                  HTML
                </span>
              </dd>
              <dd className="skill-level skill-level-holder percentage-60">
                <span className="skill-level-text">
                  CSS/SASS
                </span>
              </dd>
              <dd className="skill-level skill-level-holder percentage-50">
                <span className="skill-level-text">
                  React
                </span>
              </dd>
              <dd className="skill-level skill-level-holder percentage-50">
                <span className="skill-level-text">
                  Ember.js
                </span>
              </dd>
              <dd className="">
                <table className="skill-level-numbers">
                  <thead>
                    <tr>
                      <th>Novice</th>
                      <th>Intermediate</th>
                      <th>Expert</th>
                    </tr>
                  </thead>
                </table>
              </dd>
            </dl>
            <h5 className="skills-level-header">General Programming</h5>
            <dl>
              <dd className="skill-level skill-level-holder percentage-70">
                <span className="skill-level-text">
                  Java
                </span>
              </dd>
              <dd className="skill-level skill-level-holder percentage-60">
                <span className="skill-level-text">
                  Python
                </span>
              </dd>
              <dd className="skill-level skill-level-holder percentage-40">
                <span className="skill-level-text">
                  C
                </span>
              </dd>
              <dd className="skill-level skill-level-holder percentage-40">
                <span className="skill-level-text">
                  C++
                </span>
              </dd>
              <dd className="skill-level skill-level-holder percentage-40">
                <span className="skill-level-text">
                  Assembly
                </span>
              </dd>
              <dd className="">
                <table className="skill-level-numbers">
                  <thead>
                    <tr>
                      <th>Novice</th>
                      <th>Intermediate</th>
                      <th>Expert</th>
                    </tr>
                  </thead>
                </table>
              </dd>
            </dl>

            <h5 className="skills-level-header">Other</h5>
            <div className="skills-other">
              Here are some other <b className="skills-highlight">programs</b> and <b className="skills-highlight">IDE's</b> I have experience with:
            </div>
            <div className="skills-other-list">
              <div className="other-title">
                Programming:
              </div>
              <div className="other-items">
                Microsoft Visual Code, Eclipse, Pycharm, Sublime Text, Firebase, Git
              </div>
            </div>
            <div className="skills-other-list">
              <div className="other-title">
                Design:
              </div>
              <div className="other-items">
                Blender, Adobe (Photoshop, Premiere, After Effects, Illustrator), Audacity, Sony Vegas Pro
              </div>
            </div>
          </div>
        </div>

{/* Projects Section Start */}

        <div className="projects-banner-container">
          <div className="projects-banner-text-container">
            <h3 className="projects-banner-text">
              <span className="projects-first-letter">P</span>ROJECTS
            </h3>
          </div>
          <div className="projects-banner">
          </div>
          <div className="projects-outlet">
            <div className="projects-outlet-container">
              <div className="projects-all-container">
                <div className="project-ind-container project-holder">
                  <h5 className="project-title">
                    c4me
                  </h5>
                  <img src={c4me} className="project-image"/>
                  <div className="project-tools">
                    <div className="project-tools-highlight">Tools:</div>
                    <div className="project-tools-list">
                      <div className="project-button pb-react">ReactJS</div>
                      <div className="project-button pb-html">HTML5</div>
                      <div className="project-button pb-css">CSS</div>
                      <div className="project-button pb-js">JavaScript</div>
                      <div className="project-button pb-fb">Firebase</div>
                    </div> 
                  </div>
                  <div className="project-description">
                    c4me is a college search website that allows students to create and edit profiles, search for colleges, and see information about other students' applications to those colleges.
                  </div>
                  <div className="project-links">
                    <a className="project-link" href="https://seancarlspencer.github.io/c4me/">Link</a>
                    <a className="project-link" href="https://github.com/seancarlspencer/c4me">GitHub</a>
                  </div>
                </div>
                <hr className="hr-projects" />
                <div className="project-ind-container project-holder">
                  <h5 className="project-title">
                  Wireframer
                  </h5>
                  <img src={wireframer} className="project-image"/>
                  <div className="project-tools">
                    <div className="project-tools-highlight">Tools:</div>
                    <div className="project-tools-list">
                      <div className="project-button pb-react">ReactJS</div>
                      <div className="project-button pb-html">HTML5</div>
                      <div className="project-button pb-css">CSS</div>
                      <div className="project-button pb-js">JavaScript</div>
                      <div className="project-button pb-fb">Firebase</div>
                    </div> 
                  </div>
                  <div className="project-description">
                    Wireframer is a website that allows people to create wireframe designs.  These designs can be saved to the user's account, and design features include resizing, dragging, border options, and much more.
                  </div>
                  <div className="project-links">
                    <a className="project-link" href="https://seancarlspencer.github.io/wireframer/">Link</a>
                    <a className="project-link" href="https://github.com/seancarlspencer/wireframer">GitHub</a>
                  </div>
                </div>
                <hr className="hr-projects" />
                <div className="project-ind-container project-holder">
                  <h5 className="project-title">
                    Todo Lists
                  </h5>
                  <img src={todolists} className="project-image"/>
                  <div className="project-tools">
                    <div className="project-tools-highlight">Tools:</div>
                    <div className="project-tools-list">
                      <div className="project-button pb-react">ReactJS</div>
                      <div className="project-button pb-html">HTML5</div>
                      <div className="project-button pb-css">CSS</div>
                      <div className="project-button pb-js">JavaScript</div>
                      <div className="project-button pb-fb">Firebase</div>
                    </div> 
                  </div>
                  <div className="project-description">
                    Todo Lists is a website that allows users to create, edit, and delete todo lists.
                  </div>
                  <div className="project-links">
                    <a className="project-link" href="https://seancarlspencer.github.io/todo-lists/">Link</a>
                    <a className="project-link" href="https://github.com/seancarlspencer/todo-lists">GitHub</a>
                  </div>
                </div>
                <hr className="hr-projects" />
                <div className="project-ind-container project-holder">
                  <h5 className="project-title">
                    Mona Lisa
                  </h5>
                  <iframe className="project-image" width="560" height="315" src="https://www.youtube.com/embed/Z8slxz7dGXw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                  <div className="project-tools">
                    <div className="project-tools-highlight">Tools:</div>
                    <div className="project-tools-list">
                      <div className="project-button pb-blender">Blender</div>
                      <div className="project-button pb-audacity">Audacity</div>
                      <div className="project-button pb-react">Photoshop</div>
                      <div className="project-button pb-adobe">Premiere</div>
                    </div> 
                  </div>
                  <div className="project-description">
                    Mona Lisa is an animated horror short that was inspired by the Mona Lisa Effect.  This is the illusion that Mona Lisa always looks like she is staring at you, no matter where you are in the room.
                  </div>
                  <div className="project-links">
                    <a className="project-link" className="project-link" href="https://www.youtube.com/embed/Z8slxz7dGXw">Link</a>
                  </div>
                </div>
                <hr className="hr-projects" />
                <div className="project-ind-container project-holder">
                  <h5 className="project-title">
                    Voxel Cars
                  </h5>
                  <img src={voxelcars} className="project-image"/>
                  <div className="project-tools">
                    <div className="project-tools-highlight">Tools:</div>
                    <div className="project-tools-list">
                      <div className="project-button pb-unity">Unity</div>
                      <div className="project-button pb-cSharp">C#</div>
                    </div> 
                  </div>
                  <div className="project-description">
                    Voxel Cars is a game made in unity.  The objective of the game is to dodge the oncoming obstacles in order to clear each level.
                  </div>
                  <div className="project-links">
                    <a className="project-link" href="https://github.com/seancarlspencer/voxel-cars">GitHub</a>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </div>
        {/* <div className="projects-banner-end"></div> */}

{/* Projects Section End */}

{/* Contact Section Start */}

        <div className="contact-banner-container">
          <div className="contact-banner">
          </div>


          <div className="contact-text-container">
            <h3 className="contact-banner-text">
              <span className="contact-first-letter">C</span>ONTACT
            </h3>
          </div>


          <div className="contact-outlet">
            <div className="contact-outlet-container">
              <div className="contact-all-info">
                <div className="contact-logo-container">
                  <img className="contact-logo" src={logooutline}></img>
                </div>
                <div className="contact-info-container">
                  <div className="contact-header">
                    Thank you for visiting my page.  If you would like to contact me, feel free to do so via <span className="contact-highlight">email:</span>
                  </div>
                  <div className="contact-email">
                    <a href="mailto:seancarlspencer@gmail.com">
                      SeanCarlSpencer@gmail.com
                    </a>
                  </div>
                  <div className="contact-other">
                    or through any of the <span className="contact-highlight">links</span> below:
                  </div>
                  <div className="contact-icon-list">
                    <a href="https://www.linkedin.com/in/sean-spencer-55a611152/">
                      <img src={linkedin} className="contact-icon"/>
                    </a>
                    <a href="https://github.com/seancarlspencer">
                      <img src={github} className="contact-icon"/>
                    </a>
                    <a href="https://twitter.com/seancarlspencer">
                      <img src={twitter} className="contact-icon"/>
                    </a>
                    <a href="mailto:seancarlspencer@gmail.com">
                      <img src={email} className="contact-icon"/>
                    </a>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>

{/* Contact Section End */}
        <div className="footer">
          <div className="footer-content">
            <p>©2020 Sean C. Spencer</p>
            <p>Made in ReactJS using HTML, CSS, Javascript, and Materialize.</p>
          </div>
        </div>
      </div>
    );
  };
}

export default(HomeScreen);
import React from 'react';
import projects from '../../images/code-128.png'
import about from '../../images/user-4-128.png'
import skills from '../../images/wrench-128.png'
import contact from '../../images/email-5-128.png'
import resume from '../../images/document-128.png'
import home from '../../images/house-128.png'
import menu from '../../images/menu-4-128.png'
import resumePDF from '../../documents/Sean_Spencer_Resume_ver2.pdf'

class Navbar extends React.Component {
  constructor(props){
    super(props);
    this.menuOpen = false;
    this.adminLoggedIn = false;
  }

  scrollTo(element, mobile) {
    if (mobile){
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: element.offsetTop - 52
      });
    }
    else{
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: element.offsetTop - 77
      });
    }
  }

  scrollToSplash(element, mobile) {
    if (mobile){
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: element.offsetTop - 110
      });
    }
    else{
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: element.offsetTop - 110
      });
    }
  }

  goTo(place, mobile = false){
    let navbar = document.querySelector('.nav-wrapper');
    let navmobile = document.querySelector('.mobile-menu-popout');
    if (this.menuOpen){
      this.menuOpen = false;
      navbar.style.transform = "translateX(0)";
      navmobile.style.left = "100%";
    }
    switch(place){
      case "splash": this.scrollToSplash(document.querySelector('.splash-screen-banner'),mobile);
                    break;
      case "about": this.scrollTo(document.querySelector('.about-me-banner'),mobile);
                    break;
      case "skills": this.scrollTo(document.querySelector('.skills-banner'),mobile);
                    break;
      case "projects": this.scrollTo(document.querySelector('.projects-banner'),mobile);
                    break;
      case "contact": this.scrollTo(document.querySelector('.contact-banner'),mobile);
                    break;
      case "resume": window.open(resumePDF);
                    break;
      default: console.log("Unknown");
    }
  }

  openMobileMenu(){
    let navbar = document.querySelector('.nav-wrapper');
    let navmobile = document.querySelector('.mobile-menu-popout');
    if (this.menuOpen){
      this.menuOpen = false;
      navbar.style.transform = "translateX(0)";
      navmobile.style.left = "100%";
    }
    else{
      this.menuOpen = true;
      navbar.style.transform = "translateX(-60%)";
      navmobile.style.left = "40%";
    }
  }

  render() {

    return (
      <div>
        <div className="nav-wrapper">
          <div className="row nav-row">
            <div className="col s5 nav-main-menu">
              <div className="nav-logo">
                <a className="nav-header-link" onClick={()=>this.goTo("splash")}>
                  <div className="nav-header-text"><span className="nav-first-letter">S</span>EAN <span className="nav-first-letter">C</span>. <span className="nav-first-letter">S</span>PENCER</div>
                </a>
              </div>
              <div className="nav-logo-mobile">
                <a className="nav-header-link" onClick={()=>this.goTo("splash")}>
                  <img id="nav-image" src={home}></img>
                </a>
              </div>
            </div>
            <div className="col s7 nav-menu">
              <div className="nav-menu-default">
                <div className="nav-item">
                  <a className="nav-item-link" onClick={()=>this.goTo("about")}>
                    <img id="nav-image" src={about}></img>
                    <div className="nav-item-tooltip">About</div>
                  </a>
                </div>
                <div className="nav-item">
                  <a className="nav-item-link" onClick={()=>this.goTo("skills")}>
                    <img id="nav-image" src={skills}></img>
                    <div className="nav-item-tooltip">Skills</div>
                  </a>
                </div>
                <div className="nav-item">
                  <a className="nav-item-link" onClick={()=>this.goTo("projects")}>
                    <img id="nav-image" src={projects}></img>
                    <div className="nav-item-tooltip">Projects</div>
                  </a>
                </div>
                <div className="nav-item">
                  <a className="nav-item-link" onClick={()=>this.goTo("contact")}>
                    <img id="nav-image" src={contact}></img>
                    <div className="nav-item-tooltip">Contact</div>
                  </a>
                </div>
                <div className="nav-item">
                  <a className="nav-item-link" href={resumePDF}>
                    <img id="nav-image" src={resume}></img>
                    <div className="nav-item-tooltip">Resume</div>
                  </a>
                </div>
              </div>
              <div className="nav-mobile-menu">
                <a className="nav-menu-link"  onClick={()=>this.openMobileMenu()}>
                  <img id="nav-image" src={menu}></img>
                </a>
              </div>
            </div>
          </div>

        </div>
        <div className="mobile-menu-popout">
          <div className="nav-item-spacer">

          </div>
          <div className="nav-item-mobile" onClick={()=>this.goTo("about",true)}>
            <a className="nav-item-link">
              <img id="nav-image" src={about}></img>
              <div className="nav-item-text">About</div>
            </a>
          </div>
          <div className="nav-item-mobile" onClick={()=>this.goTo("skills",true)}>
            <a className="nav-item-link">
              <img id="nav-image" src={skills}></img>
              <div className="nav-item-text">Skills</div>
            </a>
          </div>
          <div className="nav-item-mobile" onClick={()=>this.goTo("projects",true)}>
            <a className="nav-item-link">
              <img id="nav-image" src={projects}></img>
              <div className="nav-item-text">Projects</div>
            </a>
          </div>
          <div className="nav-item-mobile" onClick={()=>this.goTo("contact",true)}>
            <a className="nav-item-link">
              <img id="nav-image" src={contact}></img>
              <div className="nav-item-text">Contact</div>
            </a>
          </div>
          <div className="nav-item-mobile" onClick={()=>this.goTo("resume",true)}>
            <a className="nav-item-link">
              <img id="nav-image" src={resume}></img>
              <div className="nav-item-text">Resume</div>
            </a>
          </div>
        </div>
      </div>
    );
  };
}

export default(Navbar);
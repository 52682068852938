import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar/Navbar.js';
import { Parallax } from 'react-scroll-parallax';
import HomeScreen from './components/home/HomeScreen.js';

function App() {
  return (
    <BrowserRouter>
          <div className="App">
            <div className="body-background"></div>
            <Navbar />
            <HomeScreen />
            {/* <Switch>
              <Route exact path="/" component={HomeScreen} />
              <Route path="/user" component={UserProfileScreen} />
              <Route path="/register" component={RegisterScreen} />
              <Route path="/login" component={LoginScreen} />
              <Route path="/high_schools" component={HighSchoolSearchScreen} />
              <Route path="/colleges" component={CollegeSearchScreen} />
              <Route path="/college/:id" component={CollegeInfoScreen} />
              <Route path="/admin" component={AdminScreen} />
              <Route path="/:any" component={HomeScreen} />
            </Switch> */}
          </div>
        </BrowserRouter>
  );
}

export default App;
